type hourType = {
  index: number
  label: string
  value: string
}

export const hourSelection = [
  {
    index: 9,
    label: '9:00 hrs',
    value: '09:00',
  },
  {
    index: 10,
    label: '10:00 hrs',
    value: '10:00',
  },
  {
    index: 11,
    label: '11:00 hrs',
    value: '11:00',
  },
  {
    index: 12,
    label: '12:00 hrs',
    value: '12:00',
  },
  {
    index: 13,
    label: '13:00 hrs',
    value: '13:00',
  },
  {
    index: 14,
    label: '14:00 hrs',
    value: '14:00',
  },
  {
    index: 15,
    label: '15:00 hrs',
    value: '15:00',
  },
  {
    index: 16,
    label: '16:00 hrs',
    value: '16:00',
  },
  {
    index: 17,
    label: '17:00 hrs',
    value: '17:00',
  },
  {
    index: 18,
    label: '18:00 hrs',
    value: '18:00',
  },
  {
    index: 19,
    label: '19:00 hrs',
    value: '19:00',
  },
  {
    index: 20,
    label: '20:00 hrs',
    value: '20:00',
  },
  {
    index: 21,
    label: '21:00 hrs',
    value: '21:00',
  },
  {
    index: 22,
    label: '22:00 hrs',
    value: '22:00',
  },
]

export const getAvailableHour = (selectedHour: string) => {
  if (!selectedHour) return []
  const hourSelectionCopy = JSON.parse(JSON.stringify(hourSelection))
  const hourSplit = selectedHour.split(':')
  const hourSelected = parseInt(hourSplit[0])

  const leftHoursAvailables = hourSelectionCopy.filter(
    (hour: hourType) => hour.index > hourSelected,
  )

  return [...leftHoursAvailables]
}
