import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: {
    opacity: 1,
    height: 'auto',
    marginTop: 10,
    marginBottom: 10,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, height: 0, marginTop: 0, marginBottom: 0 },
}

const smallVariants = {
  visible: {
    opacity: 1,
    height: 'auto',
    marginTop: 10,
    marginBottom: 20,
    transition: { duration: 0.5 },
  },
  hidden: { opacity: 0, height: 0, marginTop: 0, marginBottom: 0 },
}

export const BoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  display: flex;
  flex-direction: row;
`

export const SmallBoxStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants: smallVariants,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
`
