export default [
  {
    label: 'L',
    value: 'monday',
  },
  {
    label: 'M',
    value: 'tuesday',
  },
  {
    label: 'M',
    value: 'wednesday',
  },
  {
    label: 'J',
    value: 'thursday',
  },
  {
    label: 'V',
    value: 'friday',
  },
  {
    label: 'S',
    value: 'saturday',
  },
  {
    label: 'D',
    value: 'sunday',
  },
]

export const dayName = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miercoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sabado',
  sunday: 'Domingo',
}
