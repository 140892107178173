import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: { opacity: 1, height: 'auto', transition: { duration: 0.6 } },
  hidden: { opacity: 0, height: 0 },
}

export const CardStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  width: 85%;
  margin: auto;
  padding: 30px 20px;
  border: 1px solid #d9d9d980;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  background-color: #fff;
`

export const SmallCardStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  width: 100%;
  padding: 30px 20px;
  border: 1px solid #d9d9d980;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  background-color: #fff;
`

export const DetailStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))``
