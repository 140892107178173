import * as React from 'react'
import Calification from '@src/components/calification'
import styles from './styles.module.css'

type MeetingsTypes = {
  id: string
  key: number
  services: {
    name: string
  }[]
  rating: number
  client: string
  review: string
  date: Date
}

export const header = [
  {
    title: 'Servicios',
    dataIndex: 'services',
    key: 'services',
    render: (text: { name: string }[], record: MeetingsTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: (
          <div className={styles.headerName} style={{ fontSize: 15 }}>
            {text.map((item, idx) => {
              return <div key={idx.toString()}>- {item}</div>
            })}
          </div>
        ),
      }
    },
  },
  {
    title: 'Cliente',
    dataIndex: 'client',
    key: 'client',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Comentario',
    dataIndex: 'review',
    key: 'review',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
  {
    title: 'Calificación',
    dataIndex: 'rating',
    key: 'rating',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: text && <Calification value={text} />,
      }
    },
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
    render: (text: string, record: ProfessionalTypes) => {
      const style =
        record.key % 2
          ? { background: '#fff', color: '#221F1F' }
          : { background: 'rgba(245, 213, 242, 0.4)', color: '#221F1F' }
      return {
        props: {
          style,
        },
        children: <div style={{ fontSize: 15 }}>{text}</div>,
      }
    },
  },
]
