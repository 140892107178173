import styled from 'styled-components'
import { motion } from 'framer-motion'

const variants = {
  visible: { opacity: 1, height: 'auto', transition: { duration: 1 } },
  hidden: { opacity: 0, height: 0 },
}

export const HoursStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  margin-top: 20px;
  width: 30%;
`
export const SelectStyled = styled(motion.div).attrs(() => ({
  initial: 'hidden',
  variants,
}))`
  width: 70%;
`
