import { useMutation, useQuery } from '@apollo/client'
import SelectHour from '@src/components/fields/select'
import Loading from '@src/components/loading'
import { USER } from '@src/helpers/localQueries/userQuery'
import { UPDATE_PROFESSIONAL_SCHEDULE } from '@src/helpers/mutations/schedules.gql'
import { PROFESSIONAL_SCHEDULE } from '@src/helpers/queries/schedules.gql'
import Button from 'antd/es/button'
import React, { Fragment, useEffect, useState } from 'react'
import { MdKeyboardArrowUp } from 'react-icons/md'

import days, { dayName } from './days'
import { BoxStyled, SmallBoxStyled } from './framerStyles'
import { ScheduleStateType, ScheduleVars, UpdateScheduleVars, UserVars } from './graphqlTypes'
import { getAvailableHour, hourSelection } from './hours'
import selectStyles from './selectStyles'
import styles from './styles.module.css'

type AvailabilityProps = {
  setProfessionalAvailableDays: Function
}

const startWidth = typeof window !== 'undefined' && window.innerWidth
const startHeight = typeof window !== 'undefined' && window.innerHeight

const Availability: React.FC<AvailabilityProps> = ({
  setProfessionalAvailableDays,
}) => {
  const [availableDays, setAvailableDays] = useState<string[]>([])
  const [availableHours, setAvailableHours] = useState<ScheduleStateType>({})
  const [dimensions, setDimensions] = useState({
    width: startWidth,
    height: startHeight,
  })
  const [dataUpdated, setDataUpdated] = useState<Date>(null)
  const [updatingLoading, setUpdatingLoading] = useState<boolean>(false)
  const { data: userData } = useQuery<UserVars>(USER)
  const { loading, error, data: scheduleData, refetch: scheduleRefetch } = useQuery<ScheduleVars>(
    PROFESSIONAL_SCHEDULE,
    {
      variables: {
        professionalId: userData?.user?.professionalId,
      },
      skip: !userData?.user?.professionalId,
    },
  )
  const [updateProfessionalSchedule] = useMutation<UpdateScheduleVars>(
    UPDATE_PROFESSIONAL_SCHEDULE,
  )

  const updateDimensions = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions)
      return () => window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  useEffect(() => {
    setProfessionalAvailableDays(availableDays)
  }, [availableDays])

  useEffect(() => {
    if (scheduleData && scheduleData.getSchedule) {
      const { schedule } = scheduleData.getSchedule
      const scheduleCopy = JSON.parse(JSON.stringify({ ...schedule }))
      const days = Object.keys(schedule)
      const activeDays = []
      for (const day of days) {
        if (!schedule[day]) delete scheduleCopy[day]
        else activeDays.push(day)
      }
      setAvailableHours({ ...scheduleCopy })
      setAvailableDays([...activeDays])
    }
  }, [scheduleData])

  const updateSchedule = async () => {
    setUpdatingLoading(true)
    try {
      await updateProfessionalSchedule({
        variables: {
          input: {
            professionalId: userData?.user?.professionalId,
            schedule: availableHours,
          },
        },
      })
      scheduleRefetch()
    } catch (error) {
      console.log(error)
    }
    setUpdatingLoading(false)
  }

  useEffect(() => {
    if (!dataUpdated) return
    updateSchedule()
  }, [dataUpdated])

  const onSelectDay = (day: { label: string; value: string }) => {
    setAvailableDays(prevState => {
      if (availableDays.includes(day.value)) {
        const filterValue = prevState.filter(value => value !== day.value)
        return [...filterValue]
      } else {
        const copy = [...prevState]
        copy.push(day.value)
        return [...copy]
      }
    })

    setAvailableHours(prevState => {
      if (availableDays.includes(day.value)) {
        const dayCopy = { ...prevState }
        delete dayCopy[day.value]
        return { ...dayCopy }
      } else {
        const dayCopy = { ...prevState }
        dayCopy[day.value] = { startTime: '09:00', endTime: '22:00' }
        return { ...dayCopy }
      }
    })

    setDataUpdated(new Date())
  }

  const handleChange = (value: string, id: string, hour: string) => {
    if (!value) return
    setAvailableHours(prevState => {
      const hoursCopy = { ...prevState }
      if (!hoursCopy[id]) return { ...hoursCopy }
      else {
        hoursCopy[id][hour] = value
        return { ...hoursCopy }
      }
    })
    setDataUpdated(new Date())
  }

  const renderSelectHours = (day: string, idx: number) => {
    return (
      <Fragment key={idx.toString()}>
        <div className={styles.day}>{dayName[day]}</div>
        <div className={styles.hour}>
          <SelectHour
            styles={selectStyles}
            value={availableHours[day]?.startTime}
            options={hourSelection}
            onChange={value => handleChange(value, day, 'startTime')}
          />
        </div>
        <div className={styles.separation}>a</div>
        <div className={styles.hour}>
          <SelectHour
            styles={selectStyles}
            value={availableHours[day]?.endTime}
            options={getAvailableHour(availableHours[day]?.startTime)}
            onChange={value => handleChange(value, day, 'endTime')}
          />
        </div>
      </Fragment>
    )
  }

  const renderHours = () => {
    const Component = dimensions.width < 600 ? SmallBoxStyled : BoxStyled
    return (
      <div className={styles.allHours}>
        {days.map((day, idx) => {
          return (
            <Component
              key={idx.toString()}
              animate={
                availableDays.includes(day.value) ? 'visible' : 'hidden'
              }>
              {renderSelectHours(day.value, idx)}
            </Component>
          )
        })}
      </div>
    )
  }

  const getDayClassName = (day: { label: string; value: string }) => {
    if (availableDays.includes(day.value)) return styles.selectedDay
    return styles.normalDay
  }

  const renderDays = () => {
    return (
      <div className={styles.days}>
        {days.map((day, idx) => {
          return (
            <div
              className={getDayClassName(day)}
              key={idx.toString()}
              onClick={() => onSelectDay(day)}>
              {day.label}
            </div>
          )
        })}
      </div>
    )
  }

  const renderDescription = () => {
    return (
      <div className={styles.description}>
        Selecciona los días y horarios que estes disponibles para agendar horas.
        Los días que no hayan sidos seleccionados se verán desactivados en tu
        calendario.
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <div className={styles.header}>
        <div className={styles.title}>Disponibilidad</div>
        <MdKeyboardArrowUp size={20} />
      </div>
    )
  }

  // if (updatingLoading) return <Loading />
  if (error) return <span>Error...</span>
  return (
    <div className={styles.container}>
      {renderTitle()}
      {renderDescription()}
      {renderDays()}
      {renderHours()}
    </div>
  )
}

export default Availability
