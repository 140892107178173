export default {
  valueContainer: (data: any) => ({
    ...data,
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  indicatorsContainer: (data: any) => ({
    ...data,
    padding: 1,
  }),
  indicatorSeparator: (data: any) => ({
    ...data,
    width: 0,
  }),
  dropdownIndicator: (data: any) => ({
    ...data,
    // backgroundColor: 'blue',
    width: 0,
  }),
  menuList: (data: any) => ({
    ...data,
    maxHeight: 200,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#F5D5F2' : 'black',
    fontSize: 15,
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F5D5F2',
    },
  }),
  control: (base: any) => ({
    ...base,
    fontSize: 15,
    margin: '10px 0',
    height: '40px',
    borderRadius: '20px',
    minWidth: '30px',
    borderColor: 'rgba(128, 98, 125, 0.1)',
    backgroundColor: 'rgba(128, 98, 125, 0.1)',

    transition: 'all 0.5s ease',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #F5D5F2',
    },
  }),
}
