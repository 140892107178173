import React from 'react'
import { navigate } from 'gatsby'

type PrivateRouteProps = {
  component: Function
  location: object
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  if (location.pathname === `/authentication`) {
    navigate('/home')
    return null
  }

  if (location.pathname === '/') {
    typeof window !== 'undefined' && navigate('/home')
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
