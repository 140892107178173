type ImageTypes = {
  name: string
  uid: string
  url: string
  status: string
}

type ServiceTypes = {
  details: string
  duration: number
  id: string
  price: number
  specialtyName: string
  title: string
}

type ProfessionalType = {
  id: string
  rut: string
  name: string
  email: string
  title: string
  phone: string
  socialNetworks: {
    facebook: string
    instagram: string
    twitter: string
  }
  picture: ImageTypes[]
  bankData: {
    bank: string
    accountType: string
    accountNumber: string
  }
  portfolio: ImageTypes[]
  specialtyNames: string[]
  services: string[]
  specialties: string[]
  servicesData: ServiceTypes[]
  rating?: number
}

export default (professionalData: ProfessionalType) => {
  return {
    id: professionalData.id,
    name: professionalData.name,
    title: professionalData.title,
    picture:
      professionalData?.picture[0]?.url ||
      'https://storage.googleapis.com/the-cat-club/media/no-image.jpeg',
    personalData: {
      name: professionalData.name,
      rut: professionalData.rut,
      specialty: professionalData.title,
      phone: professionalData.phone,
      email: professionalData.email,
    },
    bank: {
      name: professionalData.bankData.bank,
      type: professionalData.bankData.accountType,
      value: professionalData.bankData.accountNumber,
    },
    socialNetwork: professionalData && { ...professionalData.socialNetworks },
    portfolio: professionalData.portfolio.map(image => image?.url),
    specialties: professionalData.specialtyNames,
    services: professionalData.servicesData
      ? professionalData.servicesData.map(service => {
          return {
            type: service.specialtyName,
            description: service.details,
            duration: service.duration,
            price: service.price,
          }
        })
      : [],
  }
}
