export default {
  indicatorSeparator: (data: any) => ({
    ...data,
    width: 0,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? 'white' : '#616161',
    fontSize: 15,
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  control: (base: any) => ({
    ...base,
    fontSize: 15,
    margin: 0,
    height: '30px',
    borderRadius: '20px',
    transition: 'all 0.5s ease',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #045EFC',
    },
  }),
}
