export default [
  {
    index: 0,
    name: '09:00 hrs',
    value: '09:00',
  },
  {
    index: 1,
    name: '09:30 hrs',
    value: '09:30',
  },
  {
    index: 2,
    name: '10:00 hrs',
    value: '10:00',
  },
  {
    index: 3,
    name: '10:30 hrs',
    value: '10:30',
  },
  {
    index: 4,
    name: '11:00 hrs',
    value: '11:00',
  },
  {
    index: 5,
    name: '11:30 hrs',
    value: '11:30',
  },
  {
    index: 6,
    name: '12:00 hrs',
    value: '12:00',
  },
  {
    index: 7,
    name: '12:30 hrs',
    value: '12:30',
  },
  {
    index: 8,
    name: '13:00 hrs',
    value: '13:00',
  },
  {
    index: 9,
    name: '13:30 hrs',
    value: '13:30',
  },
  {
    index: 10,
    name: '14:00 hrs',
    value: '14:00',
  },
  {
    index: 11,
    name: '14:30 hrs',
    value: '14:30',
  },
  {
    index: 12,
    name: '15:00 hrs',
    value: '15:00',
  },
  {
    index: 13,
    name: '15:30 hrs',
    value: '15:30',
  },
  {
    index: 14,
    name: '16:00 hrs',
    value: '16:00',
  },
  {
    index: 15,
    name: '16:30 hrs',
    value: '16:30',
  },
  {
    index: 16,
    name: '17:00 hrs',
    value: '17:00',
  },
  {
    index: 17,
    name: '17:30 hrs',
    value: '17:30',
  },
  {
    index: 18,
    name: '18:00 hrs',
    value: '18:00',
  },
  {
    index: 19,
    name: '18:30 hrs',
    value: '18:30',
  },
  {
    index: 20,
    name: '19:00 hrs',
    value: '19:00',
  },
  {
    index: 21,
    name: '19:30 hrs',
    value: '19:30',
  },
  {
    index: 22,
    name: '20:00 hrs',
    value: '20:00',
  },
  {
    index: 23,
    name: '20:30 hrs',
    value: '20:30',
  },
  {
    index: 24,
    name: '21:00 hrs',
    value: '21:00',
  },
  {
    index: 25,
    name: '21:30 hrs',
    value: '21:30',
  },
  {
    index: 26,
    name: '22:00 hrs',
    value: '22:00',
  },
]
