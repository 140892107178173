import React, { useState, useEffect, useMemo } from 'react'
import styles from './styles.module.css'
import { isSameDate } from '@src/helpers/dates'
import SelectDate from '@src/components/fields/select'
import selectStyle from './selectStyle'
import { SelectStyled } from './framerStyles'
import hours from './hours'

type ScheduleTypes = {
  id: string
  duration: number
  date: string
  startTime: string
  endTime: string
  services: { serviceName: string }[]
  client: string
}

type CenterSectionProps = {
  selectedDate: string
  calendarDays: any
  onDateChange: Function
  onAppointmentSelect: Function
  scheduleMeetings: ScheduleTypes[]
}

const CenterSection: React.FC<CenterSectionProps> = ({
  selectedDate,
  calendarDays,
  onDateChange,
  onAppointmentSelect,
  scheduleMeetings,
}) => {
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [todayAppointments, setTodayAppointments] = useState<ScheduleTypes[]>(
    [],
  )

  const selectAppoinment = (appointmentId?: string) => {
    setSelectedAppointment(appointmentId)
    onAppointmentSelect(appointmentId)
  }

  useEffect(() => {
    const getTodayAppointments = () => {
      const today = new Date(selectedDate)
      const appointments = scheduleMeetings.filter(meeting =>
        isSameDate(new Date(meeting.date), today),
      )

      selectAppoinment(null)
      if (!appointments) setTodayAppointments([])
      setTodayAppointments(appointments)
    }

    getTodayAppointments()
  }, [selectedDate, scheduleMeetings])

  if (!selectedDate) return <span />

  const getActiveIntervalStyle = (id: string) => {
    if (selectedAppointment === id) return styles.activeInterval
    return styles.interval
  }

  const getActiveAppointmentStyle = (id: string) => {
    if (selectedAppointment === id) return styles.activeAppointment
    return styles.appointment
  }

  const emptySchedule = (hour: string, idx: number) => (
    <div className={styles.schedule} key={idx.toString()}>
      <div className={styles.emptyHour}>{hour}</div>
      <div className={styles.emptyAppointment} />
    </div>
  )

  const middleHour = (hour: string, idx: number) => (
    <div className={styles.middleBlock} key={idx.toString()}>
      <div className={styles.middleHour}>{hour}</div>
      <div className={styles.middlespace} />
    </div>
  )
  const confirmedHour = (confirmed, idx) => {
    console.log(confirmed)
    return (
      <div
        key={idx.toString()}
        onClick={() => selectAppoinment(confirmed.id)}
        className={getActiveAppointmentStyle(confirmed.id)}>
        <div className={getActiveIntervalStyle(confirmed.id)}>
          {confirmed.startTime} a {confirmed.endTime} hrs
        </div>
        <div className={styles.description}>
          {confirmed.services.map((service, idx: number) => (
            <div key={idx.toString()}>- {service.serviceName}</div>
          ))}
        </div>
        <div className={styles.client}>
          Cliente: <b>{confirmed.client}</b>
        </div>
      </div>
    )
  }

  const renderSchedule = (meetingsForToday: ScheduleTypes[]) => {
    let scheduleView = JSON.parse(JSON.stringify([]))
    let appointmentConstruction = false
    let actualSelectedAppointment = JSON.parse(JSON.stringify({}))
    let appointmentHours = JSON.parse(JSON.stringify([]))
    let appointment = JSON.parse(JSON.stringify([]))
    let idx = JSON.parse(JSON.stringify(0))
    idx = JSON.parse(JSON.stringify(0))
    appointment = JSON.parse(JSON.stringify([]))
    appointmentHours = JSON.parse(JSON.stringify([]))
    actualSelectedAppointment = JSON.parse(JSON.stringify({}))
    appointmentConstruction = false
    scheduleView = JSON.parse(JSON.stringify([]))

    for (const hour of hours) {
      if (appointmentConstruction) {
        const isEndTime = actualSelectedAppointment.endTime === hour.value
        if (isEndTime) {
          const existsAppointment = meetingsForToday.find(
            appointment => appointment.startTime === hour.value,
          )

          if (existsAppointment) {
            appointmentHours.push(
              <div className={styles.hour} key={idx.toString()}>
                {' '}
              </div>,
            )
            idx++
            scheduleView.push(
              <div className={styles.schedule} key={idx.toString()}>
                <div className={styles.hours}>{appointmentHours}</div>
                {appointment}
              </div>,
            )
            idx++
            scheduleView.push(middleHour(hour.name, idx))
            idx++
            appointmentHours = []
            appointment = []
            appointmentConstruction = false
            actualSelectedAppointment = {}
            actualSelectedAppointment = { ...existsAppointment }
            appointment.push(confirmedHour(existsAppointment, idx))
            idx++
            appointmentHours.push(
              <div className={styles.hour} key={idx.toString()}>
                {' '}
              </div>,
            )
            appointmentConstruction = true
          } else {
            appointmentHours.push(
              <div className={styles.hour} key={idx.toString()}>
                {hour.name}
              </div>,
            )
            idx++
            scheduleView.push(
              <div className={styles.schedule} key={idx.toString()}>
                <div className={styles.hours}>{appointmentHours}</div>
                {appointment}
              </div>,
            )
            appointmentHours = []
            appointment = []
            appointmentConstruction = false
            actualSelectedAppointment = {}
          }
        } else {
          appointmentHours.push(
            <div className={styles.invisibleHour} key={idx.toString()}>
              -
            </div>,
          )
        }
      } else {
        const existsAppointment = meetingsForToday.find(
          appointment => appointment.startTime === hour.value,
        )
        if (existsAppointment) {
          actualSelectedAppointment = { ...existsAppointment }
          appointmentHours.push(
            <div className={styles.hour} key={idx.toString()}>
              {hour.name}
            </div>,
          )
          idx++
          appointment.push(confirmedHour(existsAppointment, idx))
          appointmentConstruction = true
        } else {
          scheduleView.push(emptySchedule(hour.name, idx))
        }
      }
      idx++
    }

    return scheduleView
  }

  const renderVerticalSchedule = () => {
    return <>{renderSchedule(todayAppointments)}</>
  }

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        <div className={styles.title}>Fecha</div>
        <SelectStyled animate={selectedDate ? 'visible' : 'hidden'}>
          <SelectDate
            styles={selectStyle}
            value={selectedDate}
            options={calendarDays}
            onChange={onDateChange}
          />
        </SelectStyled>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderHeader()}
      {renderVerticalSchedule()}
    </div>
  )
}

export default CenterSection
