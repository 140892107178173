import React from 'react'
import styles from './styles.module.css'
import BackArrow from '@src/components/back-arrow'
import { MdStayPrimaryPortrait, MdMailOutline } from 'react-icons/md'
import TitleHeader from '@src/components/titleHeader'

const Support: React.FC = () => {
  const renderContactItem = (logo, data) => {
    const Component = logo
    return (
      <div className={styles.contactItem}>
        <Component size={20} color="rgba(206, 46, 188, 1)" />
        <div className={styles.item}>{data}</div>
      </div>
    )
  }

  const renderContact = () => (
    <div className={styles.contactContent}>
      {renderContactItem(MdMailOutline, 'contacto@thecatclub.cl')}
      {renderContactItem(MdStayPrimaryPortrait, ' +56 9 9273 1293')}
    </div>
  )

  const renderText = () => (
    <div className={styles.description}>
      Si tienes algún inconveniente con tu agenda o necesitas contactarte con la
      oficina de The Cat Club, puedes enviar un correo o llamar directamente al
      número de contacto
    </div>
  )

  const renderHeader = () => (
    <div className={styles.header}>
      <BackArrow path="/home" />
      <TitleHeader label="soporte" />
    </div>
  )

  return (
    <div className={styles.container}>
      {renderHeader()}
      <div className={styles.content}>
        <div className={styles.leftContent}>{renderText()}</div>
        <div className={styles.rightContent}>{renderContact()}</div>
      </div>
    </div>
  )
}

export default Support
