import * as React from 'react'
import { Router } from '@reach/router'

import PrivateRoute from '@src/components/privateRoute'
import Home from '@src/components/pages/home'
import Profile from '@src/components/pages/profile'
import Support from '@src/components/pages/support'

import './index.less'

const App: React.FC = () => (
  <Router>
    <PrivateRoute path="/" component={null} />
    <PrivateRoute component={Home} path="/home" />
    <PrivateRoute component={Profile} path="/profile" />
    <PrivateRoute component={Support} path="/support" />
  </Router>
)

export default App
