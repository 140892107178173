import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import { useLazyQuery, useQuery } from '@apollo/client'
import CenterSection from './centerSection'
import Availability from './availability'
import Details from './details'
import { USER } from '@src/helpers/localQueries/userQuery'
import { FIND_MEETINGS_BY_MONTH } from '@src/helpers/queries/meetings.gql'
import Loading from './calendar/calendar-loading'
import { MeetingsVars, MeetingTypes, ScheduleTypes } from './queryTypes'
import Calendar from './calendar'
import daysNumber from './days'
import { isEmpty } from 'ramda'
import dayjs, { Dayjs } from 'dayjs'

interface UserData {
  id: string
  professionalId: string
  name: string
  picture: string
  title: string
  tokenId: string
  email: string
  accessToken: string
  isLoggedIn: boolean
  expiresAt: string
}

interface UserLocalQuery {
  user: UserData
}

const Home: React.FC = () => {
  const [allMeetings, setAllMeetings] = useState<MeetingTypes[]>([])
  const [calendarMonth, setCalendarMonth] = useState<number>(
    new Date().getMonth(),
  )
  const [availableDays, setAvailableDays] = useState<number[]>([])
  const [scheduleMeetings, setScheduleMeetings] = useState<ScheduleTypes[]>([])
  const [actualDate, setActualDate] = useState<string>(null)
  const [actualObjectDate, setActualObjectDate] = useState<Dayjs>(dayjs())
  const [calendarDays, setCalendarDays] = useState([])
  const [appointmentId, setAppointmetId] = useState<string>(null)
  const [getMeetings, { loading, error, data: meetings }] = useLazyQuery<
    MeetingsVars
  >(FIND_MEETINGS_BY_MONTH)
  const { data: userData } = useQuery<UserLocalQuery>(USER)

  useEffect(() => {
    if (meetings && meetings.professionalMeetingsInMonth) {
      const { professionalMeetingsInMonth } = meetings
      setAllMeetings(professionalMeetingsInMonth)
      const scheduleMeetings = professionalMeetingsInMonth.map(meeting => {
        return {
          id: meeting.id,
          duration: meeting.metadata.duration,
          date: dayjs(meeting.metadata.date).format('YYYY/MM/DD'),
          startTime: meeting.metadata.startTime,
          endTime: meeting.metadata.endTime,
          services: meeting.serviceDetails,
          client: meeting.user.auth0Data.name || meeting.user.auth0Data.email,
        }
      })
      setScheduleMeetings([...scheduleMeetings])
    }
  }, [meetings])

  useEffect(() => {
    if (!actualObjectDate) return
    getMeetings({ variables: { date: actualObjectDate.toDate() } })
  }, [])

  const onAppointmentCanceled = () => {
    getMeetings({ variables: { date: actualObjectDate.toDate() } })
    setAppointmetId(null)
  }

  const onCalendarRefresh = () =>
    getMeetings({ variables: { date: actualObjectDate.toDate() } })

  const onAppointmentSelect = (appointmentData: string) => {
    setAppointmetId(appointmentData)
  }

  const onDateChange = (date: string) => {
    if (!date) return
    setActualDate(date)
    setActualObjectDate(dayjs(new Date(date)))
  }

  const setProfessionalAvailableDays = (days: string[]) => {
    if (isEmpty(days)) setAvailableDays([])
    const available = days.map(day => daysNumber[day])
    setAvailableDays([...available])
  }

  const onPanelChange = (value: Dayjs, mode: string) => {
    getMeetings({ variables: { date: value.toDate() } })
    setCalendarMonth(value.toDate().getMonth())
  }

  const renderRightSection = () => {
    return (
      <div className={styles.details}>
        <Details
          appointmentId={appointmentId}
          allMeetings={allMeetings}
          onAppointmentSelect={onAppointmentSelect}
          onAppointmentCanceled={onAppointmentCanceled}
        />
      </div>
    )
  }

  const renderCenterSection = () => {
    return (
      <div className={styles.centerSection}>
        <CenterSection
          calendarDays={calendarDays}
          selectedDate={actualDate}
          scheduleMeetings={scheduleMeetings}
          onDateChange={onDateChange}
          onAppointmentSelect={onAppointmentSelect}
        />
      </div>
    )
  }

  const renderLeftSection = () => {
    return (
      <div className={styles.leftSection}>
        <div className={styles.calendar}>
          {loading ? (
            <Loading />
          ) : (
            <Calendar
              actualDate={actualDate}
              actualObjectDate={actualObjectDate}
              allMeetings={allMeetings}
              availableDays={availableDays}
              onPanelChange={onPanelChange}
              setActualObjectDate={setActualObjectDate}
              setActualDate={setActualDate}
              setCalendarDays={setCalendarDays}
              calendarMonth={calendarMonth}
              onCalendarRefresh={onCalendarRefresh}
            />
          )}
        </div>
        <Availability
          setProfessionalAvailableDays={setProfessionalAvailableDays}
        />
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <div className={styles.header}>
        <div className={styles.userName}>
          Bienvenido {userData?.user?.name}:
        </div>
        <div className={styles.description}>
          Aquí podrás ver todas tus horas agendadas con sus detalles
          respectivos.
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderTitle()}
      <div className={styles.content}>
        <span />
        {renderLeftSection()}
        {renderCenterSection()}
        {renderRightSection()}
      </div>
    </div>
  )
}

export default Home
