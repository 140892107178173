import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useMutation } from '@apollo/client'
import { CANCEL_MEETING } from '@src/helpers/mutations/meetings.gql'
import Modal from 'react-modal'
import { Button, message } from 'antd'
import sleep from '@src/helpers/sleep'

type CancelProps = {
  meetingId: string
  onAppointmentCanceled: Function
}

export const customStyles = {
  content: {
    height: '100%',
    width: '100%',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#00000094',
  },
}

const Cancel: React.FC<CancelProps> = ({
  meetingId,
  onAppointmentCanceled,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [cancelMeetingMut, { error: cancelMeetingError }] = useMutation(
    CANCEL_MEETING,
  )

  const cancelMeeting = async () => {
    setLoading(true)
    await sleep(1500)
    try {
      const response = await cancelMeetingMut({ variables: { meetingId } })
      if (!response || !response.data) throw new Error()
      onAppointmentCanceled()
      setOpen(false)
      message.success('Trabajo cancelado con exito')
    } catch (error) {
      message.error('Ocurrio un error al intentar cancelar el trabajo')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (
      cancelMeetingError &&
      cancelMeetingError.message ===
        'You can only cancel a meeting at least 24 hours before'
    )
      message.error(
        'No puedes cancelar una hora con menos de 24 horas de anticipación',
      )
  }, [cancelMeetingError])

  const renderModal = () => (
    <Modal isOpen={open} style={customStyles}>
      <div className={styles.modal}>
        <div className={styles.alert}>
          Confirmas que deseas cancelar este trabajo ?
        </div>
        <div className={styles.buttons}>
          <Button
            shape="round"
            danger
            onClick={cancelMeeting}
            loading={loading}>
            Confirmo cancelación
          </Button>
          <div className={styles.separation} />
          <Button onClick={() => setOpen(false)} shape="round">
            No cancelar
          </Button>
        </div>
      </div>
    </Modal>
  )

  return (
    <div className={styles.container}>
      <Button
        size="large"
        type="dashed"
        danger
        block
        shape="round"
        onClick={() => setOpen(true)}>
        Cancelar
      </Button>
      {renderModal()}
    </div>
  )
}

export default Cancel
