import React from 'react'
import styles from './styles.module.css'
import { MeetingTypes } from './queryTypes'
import Calendar from '@src/components/antd-calendar'
import RightArrow from './rightArrow'
import LeftArrow from './leftArrow'
import { buttonStyle } from './customStyles'
import { Button } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import months from './months'

type LocalCalendarProps = {
  actualDate: string
  allMeetings: MeetingTypes
  actualObjectDate: Dayjs
  availableDays: number[]
  onPanelChange: Function
  setActualObjectDate: Function
  setActualDate: Function
  setCalendarDays: Function
  calendarMonth: number
  onCalendarRefresh: Function
}

const LocalCalendar: React.FC<LocalCalendarProps> = ({
  actualDate,
  actualObjectDate,
  allMeetings,
  availableDays,
  onPanelChange,
  setActualObjectDate,
  setActualDate,
  setCalendarDays,
  calendarMonth,
  onCalendarRefresh,
}) => {
  const getCalendarMonth = (event: Dayjs) => {
    setActualObjectDate(event)
    if (actualDate) {
      const oldDate = new Date(actualDate)
      const newDate = event.toDate()
      const month = oldDate.getMonth() + 1
      const year = oldDate.getFullYear()
      const newMonth = newDate.getMonth() + 1
      const newYear = newDate.getFullYear()
      if (month === newMonth && year === newYear) {
        const day = newDate.getDate()
        setActualDate(`${newYear}/${newMonth}/${day}`)
        return
      }
    }

    const date = event.toDate()
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const days = dayjs(date).daysInMonth()
    const dates = []

    for (let i = 1; i <= days; i++) {
      const newDate = new Date(`${year}/${month}/${i}`)
      const params = {
        label: dayjs(newDate).format('dddd D, MMMM'),
        value: `${year}/${month}/${i}`,
      }
      dates.push(params)
    }

    setActualDate(`${year}/${month}/${day}`)
    setCalendarDays([...dates])
  }

  const renderCalendarHeader = ({ value, type, onChange, onTypeChange }) => {
    const normalDate = new Date(value)
    const getMonthNumber = normalDate.getMonth()
    const month = months[getMonthNumber]
    const previousMonth = new Date(
      normalDate.setMonth(normalDate.getMonth() - 1),
    )
    const nextMonth = new Date(normalDate.setMonth(normalDate.getMonth() + 2))
    return (
      <div className={styles.calendarHeader}>
        <div
          className={styles.image}
          onClick={() => onChange(dayjs(previousMonth))}>
          <LeftArrow className={styles.arrows} />
        </div>
        <div className={styles.monthName}>{month}</div>
        <div
          className={styles.image}
          onClick={() => onChange(dayjs(nextMonth))}>
          <RightArrow className={styles.arrows} />
        </div>
      </div>
    )
  }

  const onDateCellRender = (date: Dayjs, allMeetings) => {
    const isSameMonth =
      date.toDate().getMonth() === actualObjectDate.toDate().getMonth()

    if (!isSameMonth) return <span />
    const weekDay = date.toDate().getDay()
    const calendarDay = date.toDate().getDate()
    const calendarMonth = date.toDate().getMonth()
    const exists = allMeetings.find(
      meeting =>
        new Date(meeting?.metadata?.date).getDate() === calendarDay &&
        new Date(meeting?.metadata?.date).getMonth() === calendarMonth,
    )

    const today = actualObjectDate.toDate()
    const receivedCalendarDate = date.toDate()
    const isTheSameDay =
      dayjs(today).format('YYYYMMDD') ===
      dayjs(receivedCalendarDate).format('YYYYMMDD')

    const dayStyle = isTheSameDay
      ? styles.activeDay
      : availableDays.includes(weekDay)
      ? styles.day
      : styles.inactiveDay

    return exists ? (
      <div className={styles.days}>
        <div className={styles.dayContent} />
        <div className={dayStyle}>{calendarDay}</div>
      </div>
    ) : (
      <div className={styles.days}>
        <div className={styles.emptyDayContent} />
        <div className={dayStyle}>{calendarDay}</div>
      </div>
    )
  }

  const disabledDate = (event: Dayjs) => {
    const isSameMonth =
      event.toDate().getMonth() === actualObjectDate.toDate().getMonth()
    if (!isSameMonth) return true
  }

  return (
    <div className={styles.calendar}>
      <Calendar
        headerRender={renderCalendarHeader}
        disabledDate={disabledDate}
        value={actualObjectDate}
        fullscreen={false}
        onPanelChange={onPanelChange}
        onSelect={getCalendarMonth}
        dateFullCellRender={date => onDateCellRender(date, allMeetings)}
      />
      <div className={styles.calendarFooter}>
        <div className={styles.footer}>
          <div className={styles.dot} />
          <div className={styles.description}>Fechas con citas agendadas</div>
        </div>
        <div className={styles.button}>
          <Button
            style={buttonStyle}
            type="primary"
            onClick={onCalendarRefresh}>
            Actualizar
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LocalCalendar
