import React, { useState, useEffect, Fragment } from 'react'
import styles from './styles.module.css'
import { CardStyled, SmallCardStyled, DetailStyled } from './framerStyles'
import { AiFillCloseCircle } from 'react-icons/ai'
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md'
import { MeetingTypes, DetailsProps } from './types'
import { customStyles } from './styles'
import Modal from 'react-modal'
import { isEmpty } from 'ramda'
import numeral from 'numeral'
import Cancel from './cancel'
import dayjs from 'dayjs'
import 'dayjs/locale/es'

dayjs.locale('es')

const startWidth = typeof window !== 'undefined' && window.innerWidth
const startHeight = typeof window !== 'undefined' && window.innerHeight

const Details: React.FC<DetailsProps> = ({
  appointmentId,
  allMeetings,
  onAppointmentSelect,
  onAppointmentCanceled,
}) => {
  const [appointment, setAppointment] = useState<MeetingTypes>({})
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [dimensions, setDimensions] = useState({
    width: startWidth,
    height: startHeight,
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const updateDimensions = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions)
      return () => window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const deleteMeetingData = () => onAppointmentSelect(null)

  useEffect(() => {
    if (!appointmentId) {
      setAppointment({})
      setIsOpen(false)
      return
    }

    const setAppointmentDetails = () => {
      const actualAppointment = allMeetings.find(
        data => data.id === appointmentId,
      )
      if (!actualAppointment) {
        setAppointment({})
        setIsOpen(false)
      } else {
        setAppointment(actualAppointment)
        setIsOpen(true)
      }
    }

    setAppointmentDetails()
  }, [appointmentId, allMeetings])

  const getStatusStyles = (status: string) => {
    if (status === 'confirmed') return styles.confirmed
    return styles.pending
  }

  const renderClientInformation = () => (
    <div className={styles.clientInfo}>
      <div className={styles.iTitle}>Información Cliente</div>
      <div className={styles.lTitle}>Dirección</div>
      <div className={styles.data}>{appointment.address}</div>
      <div className={styles.lTitle}>Teléfono</div>
      <div className={styles.data}>{appointment.professional?.phone}</div>
      {appointment.note && (
        <Fragment>
          <div className={styles.nTitle}>Nota cliente:</div>
          <div className={styles.clientNote}>"{appointment.note}"</div>
        </Fragment>
      )}
    </div>
  )

  const renderDetailsDots = (label: string, value: any) => (
    <div className={styles.detailDot}>
      <div className={styles.detailLabel}>{label || '  -  '}</div>
      <div className={styles.detailValue}>{value || '  -  '}</div>
    </div>
  )

  const renderDetails = () => (
    <DetailStyled animate={showDetails ? 'visible' : 'hidden'}>
      {renderDetailsDots('Dirección: ', appointment.address)}
      {renderDetailsDots('Tipo de pago: ', 'Tarjeta de crédito')}
      {renderDetailsDots(
        'Monto total: ',
        numeral(appointment.totalAmount).format('$0,0'),
      )}
    </DetailStyled>
  )

  const renderServicesDetails = () => (
    <div className={styles.servicesDetails}>
      <div
        className={styles.serviceDetailTitle}
        onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? (
          <MdRemoveCircleOutline color="#ce2ebc" size={20} />
        ) : (
          <MdAddCircleOutline color="#ce2ebc" size={20} />
        )}
        <div className={styles.detailTitle}>Detalle</div>
      </div>
      {renderDetails()}
    </div>
  )

  const renderService = () => (
    <div className={styles.service}>
      <div className={styles.client}>{appointment.user?.auth0Data.name}</div>
      <div className={styles.sTitle}>Servicio</div>
      <div className={styles.description}>
        {appointment.serviceDetails.map((service, idx: number) => {
          return <div key={idx.toString()}>- {service.serviceName}</div>
        })}
      </div>
    </div>
  )

  const renderHeader = () => (
    <div className={styles.header}>
      <div className={styles.schedule}>
        <div className={styles.date}>
          {dayjs(appointment.metadata.date).format('dddd D [de] MMMM')}
        </div>
        <div className={styles.hours}>
          {appointment.metadata.startTime} a {appointment.metadata.endTime}
        </div>
      </div>
      <div className={getStatusStyles(appointment?.status)}>
        {appointment?.status === 'confirmed' ? 'Confirmada' : 'Pendiente'}
      </div>
    </div>
  )

  console.log(!!appointment?.user?.auth0Data.name, appointment)

  const confirmedHour = () => {
    console.log(appointment?.user?.auth0Data.email)
    return (
      <div className={styles.confirmedHour}>
        <div className={styles.interval}>
          {appointment.metadata.startTime} a {appointment.metadata.endTime} hrs
        </div>
        <div className={styles.description}>
          {appointment.serviceDetails.map((service, idx: number) => (
            <div key={idx.toString()}>-{service.serviceName}</div>
          ))}
        </div>
        <div className={styles.client}>
          Cliente: <b>{appointment?.user?.auth0Data.email}</b>
        </div>
      </div>
    )
  }

  const renderDetailCard = () => {
    const Component =
      dimensions.width > 0 && dimensions.width < 900
        ? SmallCardStyled
        : CardStyled

    return (
      <Component animate={appointment?.user?.auth0Data ? 'visible' : 'hidden'}>
        {renderHeader()}
        <div className={styles.divider} />
        {renderService()}
        {renderServicesDetails()}
        <div className={styles.divider} />
        {renderClientInformation()}
        <Cancel
          meetingId={appointmentId}
          onAppointmentCanceled={onAppointmentCanceled}
        />
      </Component>
    )
  }

  const renderModal = () => {
    if (isEmpty(appointment)) return <span />
    return (
      <Modal isOpen={isOpen} style={customStyles}>
        <>
          <div className={styles.close}>
            <AiFillCloseCircle
              size={30}
              color="#FFFFFF"
              onClick={deleteMeetingData}
            />
          </div>
          {confirmedHour()}
          <div className={styles.title}>Detalle hora agendada</div>
          {renderDetailCard()}
        </>
      </Modal>
    )
  }

  if (!appointmentId) return <span />
  if (dimensions.width > 0 && dimensions.width < 480) return renderModal()
  if (isEmpty(appointment)) return <span />
  return (
    <div className={styles.container}>
      <div className={styles.title}>Detalle hora agendada</div>
      {renderDetailCard()}
    </div>
  )
}

export default Details
